import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Moment from "react-moment";
import Layout from "../../components/layout";
import Markdown from "react-markdown";
import Comment from "./Comment";
import PageContent from "../../components/pagecontent";
import RightNav from "../../components/right-nav";
import Cusdis from "gatsby-plugin-cusdis";
import "../../assets/css/artical.css";

export const query = graphql`
  query PostQuery($slug: String!) {
    strapiPost(slug: { eq: $slug }) {
      id
      title
      thumbnail
      video
      description
      category {
        slug
        viewName
      }
      published_at
    }
  }
`;

const Article = ({ data }) => {
  const article = data.strapiPost;
  const seo = {
    metaTitle: article.title,
    // metaDescription: article.description,
    shareImage: article.thumbnail,
    article: true,
  };
  console.log("data", article);
  return (
    <Layout seo={seo}>
      <div>
        <div className="main_container">
          <div className="page_category_main_div">
            <PageContent />
            <div>
              {/* category_cards_main_div */}
              <div
                className=" category_cards_main_div "
                style={{ display: "flex", flexDirection: "column" }}
              >
                {/* card_div */}
                <div style={{ display: "grid" }}>
                  {/* <GatsbyImage
                    style={{
                      gridArea: "1/1",
                    }}
                    alt={`Picture for ${article.title} article`}
                    image={article.thumbnail}
                    layout="fullWidth"
                  /> */}
                  <div
                    style={{
                      // By using the same grid area for both, they are stacked on top of each other
                      gridArea: "1/1",
                      position: "relative",
                      // This centers the other elements inside the hero component
                      placeItems: "center",
                      display: "grid",
                    }}
                  >
                    <h1 style={{ color: `black` }}>{article.title}</h1>
                    <div className="button_date_div ">
                      {/* button_div */}
                      <div className="button_div">
                        <Link
                          to={`/category/${article.category?.slug}`}
                          className="button_link"
                        >
                          <button>{article?.category?.viewName}</button>
                        </Link>
                        {/* date_time_div */}
                      </div>
                      <div className="date_time_div">
                        <Moment format="MMM DD YYYY">
                          {article.published_at}
                        </Moment>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="uk-section">
                  <div className="uk-container uk-container-small">
                    <div className="artical-video">
                      <Markdown source={article.video} escapeHtml={false} />
                    </div>
                    {/* <hr className="uk-divider-small" /> */}
                    <div
                      className="uk-grid-small uk-flex-left"
                      data-uk-grid="true"
                    >
                      {/* <div style={{display:'flex' , justifyContent:'center'}}> */}
                      {/* images with alt text, style */}
                      {/* {article.thumbnail && (
                          <GatsbyImage
                            image={
                              image
                            }
                            alt={`Picture of ${article.id}`}
                            style={{ borderRadius: "50%" }}
                          />
                          <img src={article.thumbnail} alt="artical-thumanil" style={{width:'30%',}}  />
                        )} */}
                      {/* </div> */}
                      <div className="uk-width-expand">
                        <p>{article.description}</p>
                        {/* <p className="uk-margin-remove-bottom">
                          post.id {article.id}
                        </p>
                        <p className="uk-text-meta uk-margin-remove-top">
                          <Moment format="MMM DD YYYY">{article.published_at}</Moment>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <Comment title={article?.title} id={article?.id} />
              </div>
            </div>
          </div>
          <RightNav />
        </div>
      </div>
    </Layout>
  );
};

export default Article;
